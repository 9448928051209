import { indexedDbName, indexedDbVersion } from './constants'

const NotFoundMessage = 'not_found'

/**
 * IndexedDb class handles browser's indexdb database operations
 */
export class IndexDb {

  private name: string
  private indexedDBInstance: IDBDatabase | undefined

  constructor () {
    this.name = 'keyValue'
  }

  /**
   * Saves a value to browser's indexDb with a given key
   */
  setDBValue (key: string, value: string | undefined): Promise<string> {
    return this.openIndexDB().then((database: IDBDatabase) => {
      return new Promise((resolve, reject) => {
        const request = database
          .transaction([this.name], 'readwrite')
          .objectStore(this.name)
          .put({ key, value })

        request.onsuccess = () => {
          this.indexedDBInstance = undefined
          database.close()
          return resolve(key)
        }

        request.onerror = (e) => {
          this.indexedDBInstance = undefined
          database.close()
          return reject(e)
        }
      })
    })
  }

  /**
   * Read a value from browser's indexDb
   */
  async getDBValue (key: string): Promise<string> {
    let database: IDBDatabase
    try {
      database = await this.openIndexDB()
    } catch (err) {
      throw err
    }
    return new Promise((resolve, reject) => {
      const request = database
        .transaction(this.name)
        .objectStore(this.name)
        .get(key)

      request.onsuccess = () => {
        const { result } = request
        if (result) {
          this.indexedDBInstance = undefined
          database.close()
          return resolve(result.value)
        } else {
          this.indexedDBInstance = undefined
          database.close()
          return reject(new Error(NotFoundMessage))
        }
      }

      request.onerror = (event: Event) => {
        this.indexedDBInstance = undefined
        database.close()
        return reject(event)
      }
    })
  }

  /**
   * Read a value from browser's indexDb with fallback
   */
  async getDBValueOrDefault (key: string, defaultVal: string | undefined = undefined): Promise<string | undefined> {
    try {
      const result = await this.getDBValue(key)
      return result
    } catch (err) {
      if (err.message === NotFoundMessage) {
        return defaultVal
      } else {
        throw err
      }
    }
  }

  /**
   * Opens browser's IndexDB database.
   * If the database has opened already it doesn't reopen it but returns with the opened one.
   */
  private openIndexDB (): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
      if (this.indexedDBInstance) {
        return resolve(this.indexedDBInstance)
      }
      const request = indexedDB.open(indexedDbName, indexedDbVersion)

      request.onsuccess = (event: Event) => {
        this.indexedDBInstance = (event.target! as any).result
        return resolve(this.indexedDBInstance)
      }

      request.onerror = (event: Event) => {
        return reject(event)
      }

      request.onupgradeneeded = (event: Event) => {
        const database = (event.target! as any).result
        database.createObjectStore('keyValue', {
          keyPath: 'key'
        })
      }
    })
  }

  static create (): IndexDb {
    return new IndexDb()
  }
}
